import './bootstrap';

import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';

import * as Sentry from "@sentry/browser";
import {BrowserTracing} from "@sentry/tracing";

window.Alpine = Alpine;

Alpine.plugin(focus);

Alpine.start();

Sentry.init({
    dsn: "https://37ec53c0295f49d78463292844089cc0@o87168.ingest.sentry.io/6176155",

    environment: "frontend-production",

    allowUrls: [
        'connect.samos-e.com'
    ],

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
        new BrowserTracing(),
        new Sentry.Replay({
            // Additional SDK configuration goes in here, for example:
            maskAllText: false,
            blockAllMedia: true,
        }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
});

let user = null;

axios.post('/auth/user').then(res => {

    user = res.data;

    Sentry.setUser({
        id: user.id,
        username: user.name,
        email: user.email
    });

}).catch(err => {
});


const loadZendesk = (callback) => {

    const existingScript = document.getElementById('scriptId');
    if (!existingScript) {
        const script = document.createElement('script');
        script.src = 'https://static.zdassets.com/ekr/snippet.js?key=f1105d71-6ea6-4f11-b1a9-b586bc6b3cc1';
        script.id = 'ze-snippet';
        // script.setAttribute("data-turbolinks-eval", "false");
        document.body.appendChild(script);

        script.onload = () => {
            if (callback) callback();
        };
    }
};

setTimeout(() => {
    window.zESettings = {
        webWidget: {
            color: {theme: '#572FF5'},
            contactForm: {
                subject: true,
            }
        }
    };
    loadZendesk(() => {
        zE('webWidget:on', 'open', () => {
            zE('webWidget', 'identify', {
                name: user.name,
                email: user.email,
                organization: user.current_team.name
            });
            zE('webWidget', 'prefill', {
                name: {
                    value: user.name,
                },
                email: {
                    value: user.email,
                },
            });
        });

    });
}, 1000);
